// Components
// App Bar
import SimpleAppBar from "./components/appBar/simpleAppBar";
// Router
import Router from "./router";
// Wrapper
import AppWrapper from "./components/wrappers/app/appWrapper";

import stylesConfig from "./configs/stylesConfig";

// Helper Functions
import { getDesignTokens } from "./helperFunctions/getDesignTokens";

// Material UI
// Components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// Themes
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";

// React
import { useMemo } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useAppStyles } from "./AppStyles";

function App() {
  // Hooks
  // Styles
  const classes = useAppStyles();

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // Theme
  const theme = useMemo(
    () => createTheme(getDesignTokens(themeMode)),
    [themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <AppWrapper>
          <div className="App">
            <Box
              className={classes.pageContainer}
              sx={{
                display: "flex",
                backgroundColor: stylesConfig.backgroundColor,
              }}
            >
              <CssBaseline />
              <SimpleAppBar />
              <Router />
            </Box>
          </div>
        </AppWrapper>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
