// Actions
import { onActiveStepReset } from "../redux/pages/applyJobPage/applyJobPageActions";

// Components
// Forms
import ApplyJobFormContainer from "../components/forms/applyJobPage/applyJobFormContainer";
// Headers
import ApplyJobPageHeader from "../components/headers/applyJobPage/applyJobPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function ApplyJobPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(onActiveStepReset());
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <ApplyJobPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={0} lg={2}></Grid>
        <Grid item xs={12} lg={8}>
          <ApplyJobFormContainer />
        </Grid>
        <Grid item xs={0} lg={2}></Grid>
      </Grid>
    </Container>
  );
}

export default ApplyJobPage;
