export const languageEnUs = {
  // Data
  area_name_ch: "area_name_en_full",
  degree_name_ch: "degree_name_en",
  district_name_ch: "district_name_en",
  gender_type_name_ch: "gender_type_name_en_full",
  education_level_name_ch: "education_level_name_en",
  staff_contract_type_name_ch: "staff_contract_type_name_en",
  // Text
  "請先填寫 申請職位": "Please fil in Applied Position",
  "請先填寫 期望工資": "Please fil in Expected Salary",
  "期望工資 必須為數字": "Expected Salary must be Number",
  "請先填寫 最快可入職日期": "Please fil in Date Available",
  "請先填寫 電話號碼": "Please fil in Phone Num",
  "請先填寫 區域": "Please fill in Area",
  "請先填寫 地區": "Please fill in District",
  "請先填寫 英文地址": "Please fil in English Address",
  "請先填寫 別名": "Please fil in Alias",
  "請先填寫 出生日期": "Please fill in Date of Birth",
  "請先填寫 教育程度": "Please fil in Education Level",
  "請先填寫 性別": "Please fill in Gender",
  "請先填寫 身分證編號": "Please fill in HKID",
  "請先填寫 英文全名": "Please fill in English Full Name",
  "請先填寫 機構名稱": "Please fill in Institute Name",
  "請先填寫 學位": "Please fill in Degree",
  "請先填寫 學位名稱": "Please fill in Degree Name",
  "請先填寫 開始月份": "Please fill in Start Month",
  "請先填寫 技能名稱": "Please fill in Skill Name",
  "請先填寫 合約類型": "Please fill in Contract Type",
  "請先填寫 公司名稱": "Please fill in Company Name",
  "請先填寫 職位": "Please fill in Title",
  "請先填寫 工資": "Please fill in Salary",
  "工資 必須為數字": "Salary must be Number",
  "未能提交，請再試一次": "Unable to Submit.  Please try again",
  "已成功提交，請等待公司審批。": "Submitted.  Please wait for Approval",
  "確認要提交 入職申請 嗎？": "Confirm to Submit Job Application?",
  未能提交: "Unable to Submit",
  聯絡方式: "Contact",
  電話號碼: "Phone Num",
  電郵地址: "Email",
  基本資料: "Basic Info",
  中文名稱: "Chinese Name",
  英文全名: "English Name (Full)",
  別名: "Alias",
  性別: "Gender",
  身分證編號: "HKID",
  "出生日期 (日/月/年)": "Date of Birth (DD/MM/YY)",
  教育程度: "Education Level",
  "重新 ": "Re-",
  上載個人照片: "Upload Personal Photo",
  下一步: "Next Step",
  上一步: "Previous Step",
  住宅地址: "Address",
  區域: "Area",
  地區: "District",
  中文地址: "Chinese Address",
  英文地址: "English Address",
  申請詳情: "Application Details",
  申請職位: "Applied Position",
  期望工資: "Expected Salary",
  "最快可入職日期 (日/月/年)": "Date Available (DD/MM/YY)",
  提交: "Submit",
  取消: "Cancel",
  確認: "Confirm",
  "聯絡方式 / 住宅地址": "Contact / Address",
  技能s: "Skills",
  工作經驗s: "Work Experiences",
  教育s: "Educations",
  新增技能: "Create Skill",
  技能: "SKill",
  "如不需填寫技能，請按此刪除": "Click to Delete Skill (If not applicable)",
  刪除技能: "Delete Skill",
  技能名稱: "Skill Name",
  "描述 (可不填)": "Description (Can be Blank)",
  新增教育: "Create Education",
  教育: "Education",
  "如不需填寫教育，請按此刪除": "Click to Delete Education (If not applicable)",
  刪除教育: "Delete Education",
  機構名稱: "Institute Name",
  學位: "Degree",
  學位名稱: "Degree Name",
  "開始月份 (月/年)": "Start Month (MM/YY)",
  "結束月份 (月/年) (如仍在學，可不填)":
    "End Month (MM/YY) (Can be Blank if Still Studying)",
  新增工作經驗: "Create Work Experience",
  工作經驗: "Work Experience",
  "如不需填寫工作經驗，請按此刪除":
    "Click to Delete Work Experience (If not applicable)",
  刪除工作經驗: "Delete Work Experience",
  公司名稱: "Company Name",
  職位: "Title",
  "結束月份 (月/年) (如仍在職，可不填)":
    "End Month (MM/YY) (Can be Blank if Still Working)",
  "工作內容 (可不填)": "Job Description (Can be Blank)",
  與應徵工作有關: "Related to Applied Position",
  合約類型: "Contract Type",
  工資: "Salary",
  有佣金: "With Commission",
};
