// Pages
import ApplyJobPage from "./pages/applyJobPage";

// React-Router
import { Route, Routes } from "react-router-dom";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<ApplyJobPage />} />
    </Routes>
  );
}

export default Router;
