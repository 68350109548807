// Components
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function EducationBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // Options
    degreeOptions,
    // Render
    shouldShowSpacing,
    // States
    index,
    item,
  } = props;

  // Handle States
  const endMonthVar = item.endMonth ? new Date(item.endMonth) : null;
  const itemId = item.id;
  const startMonthVar = item.startMonth ? new Date(item.startMonth) : null;

  return (
    <>
      {shouldShowSpacing && (
        <SpacingBox
          // Render
          height={stylesConfig.spacingBoxMarginBottom}
        />
      )}
      <div className={classes.contentBox}>
        {/* Title */}
        <div className={classes.titleContainer}>
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" align={"left"}>
              {t("教育")} {index + 1}
            </Typography>
          </div>
          <div className={classes.titleTextContainer}>
            {checkIsDynamicFieldError("education", itemId, "instituteName") ||
            checkIsDynamicFieldError("education", itemId, "degreeField") ? (
              <Button
                endIcon={<DeleteRoundedIcon />}
                onClick={() =>
                  onDeleteDynamicFieldBtnClicked("education", itemId)
                }
                sx={{ color: stylesConfig.redTextColor }}
                variant="text"
              >
                {t("如不需填寫教育，請按此刪除")}
              </Button>
            ) : (
              <Tooltip placement="right" title={t("刪除教育")}>
                <IconButton
                  onClick={() =>
                    onDeleteDynamicFieldBtnClicked("education", itemId)
                  }
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/* Form */}
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "education",
                itemId,
                "instituteName"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "education",
                itemId,
                "instituteName"
              )}
              label={t("機構名稱")}
              margin="dense"
              name="instituteName"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "education",
                  itemId,
                  "instituteName",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.instituteName}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className={classes.formAutoCompleteContainer}
          >
            {degreeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("degree_name_ch")]}
                onChange={(event, value) =>
                  onDynamicInputFieldChange(
                    "education",
                    itemId,
                    "degreeField",
                    value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={degreeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsDynamicFieldError(
                      "education",
                      itemId,
                      "degreeField"
                    )}
                    helperText={getDynamicErrorFieldMessage(
                      "education",
                      itemId,
                      "degreeField"
                    )}
                    label={t("學位")}
                    variant="standard"
                  />
                )}
                value={item.degreeField}
              />
            )}
          </Grid>
          {item.degreeField && item.degreeField.id === 6 && (
            <Grid item xs={12} lg={6}>
              <TextField
                error={checkIsDynamicFieldError(
                  "education",
                  itemId,
                  "degreeName"
                )}
                fullWidth
                helperText={getDynamicErrorFieldMessage(
                  "education",
                  itemId,
                  "degreeName"
                )}
                label={t("學位名稱")}
                margin="dense"
                name="degreeName"
                onChange={(event) =>
                  onDynamicInputFieldChange(
                    "education",
                    itemId,
                    "degreeName",
                    event.target.value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                value={item.degreeName}
                variant="standard"
              />
            </Grid>
          )}
          <Grid item xs={12} lg={6} className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("開始月份 (月/年)")}
              onChange={(value) =>
                onDynamicInputFieldChange(
                  "education",
                  itemId,
                  "startMonth",
                  value
                )
              }
              slotProps={{
                textField: {
                  error: checkIsDynamicFieldError(
                    "education",
                    itemId,
                    "startMonth"
                  ),
                  helperText: getDynamicErrorFieldMessage(
                    "education",
                    itemId,
                    "startMonth"
                  ),
                  variant: "standard",
                },
              }}
              value={startMonthVar}
              views={["year", "month"]}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("結束月份 (月/年) (如仍在學，可不填)")}
              onChange={(value) =>
                onDynamicInputFieldChange(
                  "education",
                  itemId,
                  "endMonth",
                  value
                )
              }
              slotProps={{
                textField: {
                  error: checkIsDynamicFieldError(
                    "education",
                    itemId,
                    "endMonth"
                  ),
                  helperText: getDynamicErrorFieldMessage(
                    "education",
                    itemId,
                    "endMonth"
                  ),
                  variant: "standard",
                },
              }}
              value={endMonthVar}
              views={["year", "month"]}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default EducationBox;
