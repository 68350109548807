export const createInitialWorkExperienceItem = () => {
  return {
    id: 1,
    staffContractTypeField: null,
    companyName: "",
    titleName: "",
    startMonth: null,
    endMonth: null,
    jobDescription: "",
    isRelatedToJobApplied: true,
    salary: "",
    isCommission: false,
  };
};

export const createWorkExperienceItem = (currentWorkExperiences) => {
  const itemId = currentWorkExperiences[0]
    ? currentWorkExperiences[currentWorkExperiences.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    staffContractTypeField: null,
    companyName: "",
    titleName: "",
    startMonth: null,
    endMonth: null,
    jobDescription: "",
    isRelatedToJobApplied: true,
    salary: "",
    isCommission: false,
  };
};
