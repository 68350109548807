// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ApplyJobFormApplicationDetailsContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    dateAvailable,
    expectedSalary,
    formAlertText,
    formAlertType,
    shouldShowFormAlert,
    titleApplied,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onNextStepBtnClicked,
    onStepBackBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const dateAvailableVar = dateAvailable ? new Date(dateAvailable) : null;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請詳情")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("titleApplied")}
            fullWidth
            helperText={getErrorFieldMessage("titleApplied")}
            label={t("申請職位")}
            margin="dense"
            name="titleApplied"
            onChange={(event) =>
              onInputFieldChange("titleApplied", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={titleApplied}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("expectedSalary")}
            fullWidth
            helperText={getErrorFieldMessage("expectedSalary")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            label={t("期望工資")}
            margin="dense"
            name="expectedSalary"
            onChange={(event) =>
              onInputFieldChange("expectedSalary", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={expectedSalary}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.formDatePickerContainer}>
          <DatePicker
            format="dd/MM/yyyy"
            label={t("最快可入職日期 (日/月/年)")}
            onChange={(value) => onInputFieldChange("dateAvailable", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("dateAvailable"),
                helperText: getErrorFieldMessage("dateAvailable"),
                variant: "standard",
              },
            }}
            value={dateAvailableVar}
            views={["year", "month", "day"]}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onNextStepBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("提交")}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button onClick={onStepBackBtnClicked} sx={formSubmitBtnStyles}>
            {t("上一步")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ApplyJobFormApplicationDetailsContent;
