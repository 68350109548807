// Configs
import colorsConfig from "./colorsConfig";

const stylesConfig = {
  appBarHeight: "80px",
  appBarBackgroundColorDark: "rgba(30, 38, 52, 0.95)",
  appBarBackgroundColorLight: "rgba(246, 248, 250, 0.95)",
  appBarContainerBackgroundColorDark: "rgba(2, 10, 27, 0.8)",
  appBarContainerBackgroundColorLight: "rgba(255, 255, 255, 0)",
  backgroundColor: "backgroundColor",
  borderRadiusLarge: 50,
  borderRadiusSmall: 25,
  borderRadiusExtraSmall: 10,
  borderRadiusTiny: 3,
  contentBackgroundColorDark: "#273042",
  contentBackgroundColorLight: "#ffffff",
  contentPaddingTop: 0,
  contentPaddingBottom: 120,
  greenTextColor: "#78b66f",
  greyBackGroundColor: "#e2e2e2",
  greyTextColor: "text.secondary",
  greyTextColorDark: "rgba(255, 255, 255, 0.7)",
  greyTextColorLight: "rgba(0, 0, 0, 0.6)",
  leftDrawerHeight: "60px",
  leftDrawerItemPadding: "20px",
  leftDrawerWidth: 240,
  mainTextColor: colorsConfig.mainColor,
  mainTextColorDark: "#ffffff",
  mainTextColorLight: "rgba(0, 0, 0, 0.87)",
  pieChartContainerHeight: 500,
  pieChartContainerWidth: "100%",
  pieChartHeight: 500,
  pieChartInnerRadius: 150,
  pieChartOuterRadius: 200,
  pieChartWidth: 800,
  redTextColor: "#f44336",
  titleTextMaxWidth: "500px",
  titleTypeTextMargin: "120px",
  shadowLargeDark: "10px 10px 20px #142128",
  shadowLargeLight: "10px 10px 20px #b4c1c8",
  shadowTinyDark: "2px 2px 4px #142128",
  shadowTinyLight: "2px 2px 4px #b4c1c8",
  spacingBoxMarginBottom: "40px",
  spacingBoxMarginBottomSmall: "10px",
  whiteTextColor: "#ffffff",
};

export default stylesConfig;
