// Actions
import {
  onActiveStepDecrease,
  onActiveStepIncrease,
  onActiveStepReset,
} from "../../../redux/pages/applyJobPage/applyJobPageActions";

// Components
// Boxes
import EducationBoxesContainer from "../../boxes/applyJobPage/educationBoxesContainer";
import SkillBoxesContainer from "../../boxes/applyJobPage/skillBoxesContainer";
import WorkExperienceBoxesContainer from "../../boxes/applyJobPage/workExperienceBoxesContainer";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import ApplyJobFormAddressContent from "./applyJobFormAddressContent";
import ApplyJobFormApplicationDetailsContent from "./applyJobFormApplicationDetailsContent";
import ApplyJobFormBasicInfoContent from "./applyJobFormBasicInfoContent";
import ApplyJobFormContactContent from "./applyJobFormContactContent";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { createCandidateFetch } from "../../../fetches/candidateFetches";
import { getJobApplicationFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Helper Functions
import {
  createInitialEducationItem,
  createEducationItem,
} from "../../../helperFunctions/createEducationItem";
import {
  createInitialSkillItem,
  createSkillItem,
} from "../../../helperFunctions/createSkillItem";
import {
  createInitialWorkExperienceItem,
  createWorkExperienceItem,
} from "../../../helperFunctions/createWorkExperienceItem";
import { getMonthStartDate } from "../../../helperFunctions/getDate";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ApplyJobFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const activeStep = useSelector((state) => state.applyJobPage.activeStep);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [dateAvailable, setDateAvailable] = useState(null);
  const [dateBirth, setDateBirth] = useState(null);
  // Dialog
  const [applyJobActionDialogText, setApplyJobActionDialogText] = useState("");
  const [applyJobActionDialogType, setApplyJobActionDialogType] =
    useState(null);
  const [showApplyJobActionDialog, setShowApplyJobActionDialog] =
    useState(false);
  // Dynamic Fields
  const [educations, setEducations] = useState([createInitialEducationItem()]);
  const [skills, setSkills] = useState([createInitialSkillItem()]);
  const [workExperiences, setWorkExperiences] = useState([
    createInitialWorkExperienceItem(),
  ]);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  const [dynamicErrorFields, setDynamicErrorFields] = useState([]);
  // File Fields
  const [photo, setPhoto] = useState(null);
  // Options Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [educationLevelField, setEducationLevelField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  const [staffContractTypeOptions, setStaffContractTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [addressCh, setAddressCh] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [hkid, setHkid] = useState("");
  const [fullNameCh, setFullNameCh] = useState("");
  const [fullNameEn, setFullNameEn] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [titleApplied, setTitleApplied] = useState("");

  // Events
  // Events - Dialogs
  const onApplyJobActionDialogCanceled = () => {
    // Set States
    setShowApplyJobActionDialog(false);
  };

  const onApplyJobActionDialogConfirmed = () => {
    switch (applyJobActionDialogType) {
      case "SubmissionFailed":
        break;
      case "SubmissionSucceed":
        resetAll();
        break;
      case "SubmitApplication":
        createCandidate();
        break;
      default:
        break;
    }

    // Set States
    setShowApplyJobActionDialog(false);
  };

  // Events - Fields
  const onDynamicInputFieldChange = (field, id, subField, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "education":
        setEducations((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;
            }

            if (subField === "degreeField" && value.id !== 6) {
              item["degreeName"] = "";
            }
          }

          return tempState;
        });
        break;
      case "skill":
        setSkills((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;
            }
          }

          return tempState;
        });
        break;
      case "workExperience":
        setWorkExperiences((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;
            }
          }

          return tempState;
        });
        break;
      default:
        break;
    }

    removeDynamicErrorField(field, id, subField);
  };

  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "addressCh":
        setAddressCh(value);
        break;
      case "addressEn":
        setAddressEn(value);
        break;
      case "alias":
        setAlias(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          districtOptions.find((item) => item.area_id === value.id)
        );

        removeErrorField("districtField");

        break;
      case "dateAvailable":
        setDateAvailable(value);
        break;
      case "dateBirth":
        setDateBirth(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "expectedSalary":
        setExpectedSalary(value);
        break;
      case "educationLevelField":
        setEducationLevelField(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      case "fullNameCh":
        setFullNameCh(value);
        break;
      case "fullNameEn":
        setFullNameEn(value);
        break;
      case "phoneNum":
        setPhoneNum(value);
        break;
      case "photo":
        setPhoto(value);
        break;
      case "titleApplied":
        setTitleApplied(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      switch (activeStep) {
        case 0:
          onApplyJobFormBasicInfoContentSubmitBtnClicked();
          break;
        case 1:
          onApplyJobFormContactAddressContentSubmitBtnClicked();
          break;
        case 2:
          onApplyJobFormSkillContentSubmitBtnClicked();
          break;
        default:
          break;
      }
    }
  };

  // Events - Forms
  const onAddDynamicFieldBtnClicked = (field) => {
    // Set States
    switch (field) {
      case "education":
        setEducations((currentState) => [
          ...currentState,
          createEducationItem(currentState),
        ]);

        break;
      case "skill":
        setSkills((currentState) => [
          ...currentState,
          createSkillItem(currentState),
        ]);

        break;
      case "workExperience":
        setWorkExperiences((currentState) => [
          ...currentState,
          createWorkExperienceItem(currentState),
        ]);

        break;
      default:
        break;
    }
  };

  const onApplyJobFormApplicationDetailsContentSubmitBtnClicked = () => {
    let isError = false;

    if (!titleApplied) {
      addToErrorFields("titleApplied", t("請先填寫 申請職位"));
      isError = true;
    }

    if (!expectedSalary) {
      addToErrorFields("expectedSalary", t("請先填寫 期望工資"));
      isError = true;
    } else {
      if (isNaN(expectedSalary)) {
        addToErrorFields("expectedSalary", t("期望工資 必須為數字"));
        isError = true;
      }
    }

    if (!dateAvailable) {
      addToErrorFields("dateAvailable", t("請先填寫 最快可入職日期"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayApplyJobActionDialog("SubmitApplication");
  };

  const onApplyJobFormContactAddressContentSubmitBtnClicked = () => {
    let isError = false;

    if (!phoneNum) {
      addToErrorFields("phoneNum", t("請先填寫 電話號碼"));
      isError = true;
    }

    if (!areaField) {
      addToErrorFields("areaField", t("請先填寫 區域"));
      isError = true;
    }

    if (!districtField) {
      addToErrorFields("districtField", t("請先填寫 地區"));
      isError = true;
    }

    if (!addressEn) {
      addToErrorFields("addressEn", t("請先填寫 英文地址"));
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onApplyJobFormBasicInfoContentSubmitBtnClicked = () => {
    let isError = false;

    if (!alias) {
      addToErrorFields("alias", t("請先填寫 別名"));
      isError = true;
    }

    if (!dateBirth) {
      addToErrorFields("dateBirth", t("請先填寫 出生日期"));
      isError = true;
    }

    if (!educationLevelField) {
      addToErrorFields("educationLevelField", t("請先填寫 教育程度"));
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", t("請先填寫 性別"));
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", t("請先填寫 身分證編號"));
      isError = true;
    }

    if (!fullNameEn) {
      addToErrorFields("fullNameEn", t("請先填寫 英文全名"));
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onApplyJobFormEducationContentSubmitBtnClicked = () => {
    let isError = false;

    for (let item of educations) {
      if (!item.instituteName) {
        addToDynamicErrorFields(
          "education",
          item.id,
          "instituteName",
          t("請先填寫 機構名稱")
        );
        isError = true;
      }

      if (!item.degreeField) {
        addToDynamicErrorFields(
          "education",
          item.id,
          "degreeField",
          t("請先填寫 學位")
        );
        isError = true;
      } else {
        if (item.degreeField.id === 6 && !item.degreeName) {
          addToDynamicErrorFields(
            "education",
            item.id,
            "degreeName",
            t("請先填寫 學位名稱")
          );
          isError = true;
        }
      }

      if (!item.startMonth) {
        addToDynamicErrorFields(
          "education",
          item.id,
          "startMonth",
          t("請先填寫 開始月份")
        );
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onApplyJobFormSkillContentSubmitBtnClicked = () => {
    let isError = false;

    for (let item of skills) {
      if (!item.skillName) {
        addToDynamicErrorFields(
          "skill",
          item.id,
          "skillName",
          t("請先填寫 技能名稱")
        );
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onApplyJobFormWorkExperienceContentSubmitBtnClicked = () => {
    let isError = false;

    for (let item of workExperiences) {
      if (!item.staffContractTypeField) {
        addToDynamicErrorFields(
          "workExperience",
          item.id,
          "staffContractTypeField",
          t("請先填寫 合約類型")
        );
        isError = true;
      }

      if (!item.companyName) {
        addToDynamicErrorFields(
          "workExperience",
          item.id,
          "companyName",
          t("請先填寫 公司名稱")
        );
        isError = true;
      }

      if (!item.titleName) {
        addToDynamicErrorFields(
          "workExperience",
          item.id,
          "titleName",
          t("請先填寫 職位")
        );
        isError = true;
      }

      if (!item.startMonth) {
        addToDynamicErrorFields(
          "workExperience",
          item.id,
          "startMonth",
          t("請先填寫 開始月份")
        );
        isError = true;
      }

      if (!item.salary) {
        addToDynamicErrorFields(
          "workExperience",
          item.id,
          "salary",
          t("請先填寫 工資")
        );
        isError = true;
      } else {
        if (isNaN(item.salary)) {
          addToDynamicErrorFields(
            "workExperience",
            item.id,
            "salary",
            t("工資 必須為數字")
          );
          isError = true;
        }
      }
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onDeleteDynamicFieldBtnClicked = (field, id) => {
    // Set States
    switch (field) {
      case "education":
        setEducations((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      case "skill":
        setSkills((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      case "workExperience":
        setWorkExperiences((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      default:
        break;
    }

    removeAllDynamicErrorField(field, id);
  };

  const onStepBackBtnClicked = () => {
    if (activeStep !== 0) {
      // Update Redux Store
      dispatch(onActiveStepDecrease());
    }
  };

  // Functions
  // Functions - Normal
  const addToDynamicErrorFields = (field, id, subField, message) => {
    if (
      dynamicErrorFields.some(
        (item) =>
          item.field === field && item.id === id && item.subField === subField
      )
    ) {
      return;
    }

    // Set States
    setDynamicErrorFields((currentState) => [
      ...currentState,
      { field, id, subField, message },
    ]);
  };

  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsDynamicFieldError = (field, id, subField) => {
    return dynamicErrorFields.some(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const displayApplyJobActionDialog = (applyJobActionType) => {
    // Set States
    setApplyJobActionDialogType(applyJobActionType);

    switch (applyJobActionType) {
      case "SubmissionFailed":
        setApplyJobActionDialogText(t("未能提交，請再試一次"));
        break;
      case "SubmissionSucceed":
        setApplyJobActionDialogText(t("已成功提交，請等待公司審批。"));
        break;
      case "SubmitApplication":
        setApplyJobActionDialogText(t("確認要提交 入職申請 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowApplyJobActionDialog(true);
  };

  const getDynamicErrorFieldMessage = (field, id, subField) => {
    const targetField = dynamicErrorFields.find(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeAllDynamicErrorField = (field, id) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field || item.id !== id)
    );
  };

  const removeDynamicErrorField = (field, id, subField) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) =>
          item.field !== field || item.id !== id || item.subField !== subField
      )
    );
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const resetAll = () => {
    // Set States
    // Date Fields
    setDateAvailable(null);
    setDateBirth(null);
    // Dynamic Fields
    setEducations([createInitialEducationItem()]);
    setSkills([createInitialSkillItem()]);
    setWorkExperiences([createInitialWorkExperienceItem()]);
    // Error Fields
    setErrorFields([]);
    setDynamicErrorFields([]);
    // File Fields
    setPhoto(null);
    // Options Fields
    setAreaField(null);
    setDistrictField(null);
    setEducationLevelField(null);
    setGenderTypeField(null);
    // Text Fields
    setAddressCh("");
    setAddressEn("");
    setAlias("");
    setEmail("");
    setExpectedSalary("");
    setHkid("");
    setFullNameCh("");
    setFullNameEn("");
    setPhoneNum("");
    setTitleApplied("");

    // Update Redux Store
    dispatch(onActiveStepReset());
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createCandidate = async () => {
    if (workExperiences && workExperiences[0]) {
      for (let item of workExperiences) {
        item.staffContractTypeId = item.staffContractTypeField
          ? item.staffContractTypeField.id
          : null;

        item.startMonth = item.startMonth
          ? getMonthStartDate(item.startMonth)
          : null;

        item.endMonth = item.endMonth ? getMonthStartDate(item.endMonth) : null;
      }
    }

    if (educations && educations[0]) {
      for (let item of educations) {
        item.degreeId = item.degreeField ? item.degreeField.id : null;

        item.startMonth = item.startMonth
          ? getMonthStartDate(item.startMonth)
          : null;

        item.endMonth = item.endMonth ? getMonthStartDate(item.endMonth) : null;
      }
    }

    const results = await createCandidateFetch(
      hkid,
      genderTypeField ? genderTypeField.id : null,
      fullNameCh,
      fullNameEn,
      alias,
      dateBirth,
      educationLevelField ? educationLevelField.id : null,
      photo ? photo : null,
      email,
      phoneNum,
      districtField ? districtField.id : null,
      addressCh,
      addressEn,
      dateAvailable,
      expectedSalary,
      titleApplied,
      skills,
      workExperiences,
      educations
    );

    if (results.success) {
      displayApplyJobActionDialog("SubmissionSucceed");
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getJobApplicationFormOptions = async () => {
    const results = await getJobApplicationFormOptionsFetch();

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDegreeOptions(results.degrees ? results.degrees : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setEducationLevelOptions(
      results.educationLevels ? results.educationLevels : []
    );
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
    setStaffContractTypeOptions(
      results.staffContractTypes ? results.staffContractTypes : []
    );
  };

  // Life Cycle
  useEffect(() => {
    getJobApplicationFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (
      !isOptionsRetrieved &&
      areaOptions[0] &&
      degreeOptions[0] &&
      districtOptions[0] &&
      educationLevelOptions[0] &&
      genderTypeOptions[0] &&
      staffContractTypeOptions[0]
    ) {
      setIsOptionsRetrieved(true);
    }
  }, [
    areaOptions,
    degreeOptions,
    districtOptions,
    educationLevelOptions,
    genderTypeOptions,
    staffContractTypeOptions,
  ]);

  useEffect(() => {
    // Set States
    setShouldShowFormAlert(false);
  }, [activeStep]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onApplyJobActionDialogCanceled}
        onDialogConfirmed={onApplyJobActionDialogConfirmed}
        // States
        dialogText={applyJobActionDialogText}
        showDialog={showApplyJobActionDialog}
      />
      {activeStep === 0 && (
        <ApplyJobFormBasicInfoContent
          // States
          alias={alias}
          dateBirth={dateBirth}
          educationLevelField={educationLevelField}
          educationLevelOptions={educationLevelOptions}
          genderTypeField={genderTypeField}
          genderTypeOptions={genderTypeOptions}
          hkid={hkid}
          fullNameCh={fullNameCh}
          fullNameEn={fullNameEn}
          photo={photo}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={onApplyJobFormBasicInfoContentSubmitBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 1 && (
        <>
          <ApplyJobFormContactContent
            // States
            email={email}
            phoneNum={phoneNum}
            // Events
            onInputFieldChange={onInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            // Functions
            checkIsFieldError={checkIsFieldError}
            getErrorFieldMessage={getErrorFieldMessage}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
          <ApplyJobFormAddressContent
            // States
            addressCh={addressCh}
            addressEn={addressEn}
            areaField={areaField}
            areaOptions={areaOptions}
            districtField={districtField}
            districtOptions={districtOptions}
            // Events
            onInputFieldChange={onInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            onNextStepBtnClicked={
              onApplyJobFormContactAddressContentSubmitBtnClicked
            }
            onStepBackBtnClicked={onStepBackBtnClicked}
            // Functions
            checkIsFieldError={checkIsFieldError}
            getErrorFieldMessage={getErrorFieldMessage}
          />
        </>
      )}
      {activeStep === 2 && (
        <SkillBoxesContainer
          // Events
          onAddDynamicFieldBtnClicked={onAddDynamicFieldBtnClicked}
          onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
          onDynamicInputFieldChange={onDynamicInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={onApplyJobFormSkillContentSubmitBtnClicked}
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsDynamicFieldError={checkIsDynamicFieldError}
          getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
          // States
          skills={skills}
        />
      )}
      {activeStep === 3 && (
        <WorkExperienceBoxesContainer
          // Events
          onAddDynamicFieldBtnClicked={onAddDynamicFieldBtnClicked}
          onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
          onDynamicInputFieldChange={onDynamicInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onApplyJobFormWorkExperienceContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsDynamicFieldError={checkIsDynamicFieldError}
          getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
          // Options
          staffContractTypeOptions={staffContractTypeOptions}
          // States
          workExperiences={workExperiences}
        />
      )}
      {activeStep === 4 && (
        <EducationBoxesContainer
          // Events
          onAddDynamicFieldBtnClicked={onAddDynamicFieldBtnClicked}
          onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
          onDynamicInputFieldChange={onDynamicInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={onApplyJobFormEducationContentSubmitBtnClicked}
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsDynamicFieldError={checkIsDynamicFieldError}
          getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
          // Options
          degreeOptions={degreeOptions}
          // States
          educations={educations}
        />
      )}
      {activeStep === 5 && (
        <ApplyJobFormApplicationDetailsContent
          // States
          dateAvailable={dateAvailable}
          expectedSalary={expectedSalary}
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          shouldShowFormAlert={shouldShowFormAlert}
          titleApplied={titleApplied}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onApplyJobFormApplicationDetailsContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
    </>
  );
}

export default ApplyJobFormContainer;
