// Components
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkExperienceBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // Options
    staffContractTypeOptions,
    // Render
    shouldShowSpacing,
    // States
    index,
    item,
  } = props;

  // Handle States
  const endMonthVar = item.endMonth ? new Date(item.endMonth) : null;
  const itemId = item.id;
  const startMonthVar = item.startMonth ? new Date(item.startMonth) : null;

  return (
    <>
      {shouldShowSpacing && (
        <SpacingBox
          // Render
          height={stylesConfig.spacingBoxMarginBottom}
        />
      )}
      <div className={classes.contentBox}>
        {/* Title */}
        <div className={classes.titleContainer}>
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" align={"left"}>
              {t("工作經驗")} {index + 1}
            </Typography>
          </div>
          <div className={classes.titleTextContainer}>
            {checkIsDynamicFieldError(
              "workExperience",
              itemId,
              "companyName"
            ) ||
            checkIsDynamicFieldError("workExperience", itemId, "titleName") ? (
              <Button
                endIcon={<DeleteRoundedIcon />}
                onClick={() =>
                  onDeleteDynamicFieldBtnClicked("workExperience", itemId)
                }
                sx={{ color: stylesConfig.redTextColor }}
                variant="text"
              >
                {t("如不需填寫工作經驗，請按此刪除")}
              </Button>
            ) : (
              <Tooltip placement="right" title={t("刪除工作經驗")}>
                <IconButton
                  onClick={() =>
                    onDeleteDynamicFieldBtnClicked("workExperience", itemId)
                  }
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/* Form */}
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "workExperience",
                itemId,
                "companyName"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "workExperience",
                itemId,
                "companyName"
              )}
              label={t("公司名稱")}
              margin="dense"
              name="companyName"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "companyName",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.companyName}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "workExperience",
                itemId,
                "titleName"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "workExperience",
                itemId,
                "titleName"
              )}
              label={t("職位")}
              margin="dense"
              name="titleName"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "titleName",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.titleName}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("開始月份 (月/年)")}
              onChange={(value) =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "startMonth",
                  value
                )
              }
              slotProps={{
                textField: {
                  error: checkIsDynamicFieldError(
                    "workExperience",
                    itemId,
                    "startMonth"
                  ),
                  helperText: getDynamicErrorFieldMessage(
                    "workExperience",
                    itemId,
                    "startMonth"
                  ),
                  variant: "standard",
                },
              }}
              value={startMonthVar}
              views={["year", "month"]}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("結束月份 (月/年) (如仍在職，可不填)")}
              onChange={(value) =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "endMonth",
                  value
                )
              }
              slotProps={{
                textField: {
                  error: checkIsDynamicFieldError(
                    "workExperience",
                    itemId,
                    "endMonth"
                  ),
                  helperText: getDynamicErrorFieldMessage(
                    "workExperience",
                    itemId,
                    "endMonth"
                  ),
                  variant: "standard",
                },
              }}
              value={endMonthVar}
              views={["year", "month"]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "workExperience",
                itemId,
                "jobDescription"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "workExperience",
                itemId,
                "jobDescription"
              )}
              label={t("工作內容 (可不填)")}
              margin="dense"
              multiline
              name="jobDescription"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "jobDescription",
                  event.target.value
                )
              }
              value={item.jobDescription}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("與應徵工作有關")}
            </Typography>
            <Switch
              checked={item.isRelatedToJobApplied}
              onChange={() =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "isRelatedToJobApplied",
                  !item.isRelatedToJobApplied
                )
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className={classes.formAutoCompleteContainer}
          >
            {staffContractTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("staff_contract_type_name_ch")]
                }
                onChange={(event, value) =>
                  onDynamicInputFieldChange(
                    "workExperience",
                    itemId,
                    "staffContractTypeField",
                    value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={staffContractTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsDynamicFieldError(
                      "workExperience",
                      itemId,
                      "staffContractTypeField"
                    )}
                    helperText={getDynamicErrorFieldMessage(
                      "workExperience",
                      itemId,
                      "staffContractTypeField"
                    )}
                    label={t("合約類型")}
                    variant="standard"
                  />
                )}
                value={item.staffContractTypeField}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "workExperience",
                itemId,
                "salary"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "workExperience",
                itemId,
                "salary"
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              label={t("工資")}
              margin="dense"
              name="salary"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "salary",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.salary}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("有佣金")}
            </Typography>
            <Switch
              checked={item.isCommission}
              onChange={() =>
                onDynamicInputFieldChange(
                  "workExperience",
                  itemId,
                  "isCommission",
                  !item.isCommission
                )
              }
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default WorkExperienceBox;
