// Components
// Boxes
import SkillBox from "./skillBox";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Button from "@mui/material/Button";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SkillBoxesContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onAddDynamicFieldBtnClicked,
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    onNextStepBtnClicked,
    onStepBackBtnClicked,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // States
    skills,
  } = props;

  // Handle States
  const itemsArr = skills;

  return (
    <>
      {/* List */}
      {itemsArr &&
        itemsArr[0] &&
        itemsArr.map((item, index) => (
          <SkillBox
            key={item.id}
            // Events
            onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
            onDynamicInputFieldChange={onDynamicInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            // Functions
            checkIsDynamicFieldError={checkIsDynamicFieldError}
            getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
            // Render
            shouldShowSpacing={index !== 0}
            // States
            index={index}
            item={item}
          />
        ))}
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <div className={classes.formSubmitBtnContainer}>
        <Button
          onClick={() => onAddDynamicFieldBtnClicked("skill")}
          variant="contained"
          sx={formSubmitBtnStyles}
        >
          {t("新增技能")}
        </Button>
      </div>
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <div className={classes.formSubmitBtnContainer}>
        <Button
          onClick={onNextStepBtnClicked}
          variant="contained"
          sx={formSubmitBtnStyles}
        >
          {t("下一步")}
        </Button>
      </div>
      <div className={classes.formSubmitBtnContainer}>
        <Button onClick={onStepBackBtnClicked} sx={formSubmitBtnStyles}>
          {t("上一步")}
        </Button>
      </div>
    </>
  );
}

export default SkillBoxesContainer;
