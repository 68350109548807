// Redux
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducers
// Pages
import { applyJobPageReducers } from "./pages/applyJobPage/applyJobPageReducers";
// Shared
import { languageReducers } from "./shared/language/languageReducers";
import { themeReducers } from "./shared/theme/themeReducers";

const rootReducer = combineReducers({
  // Pages
  applyJobPage: applyJobPageReducers,
  // Shared
  language: languageReducers,
  theme: themeReducers,
});

export const store = configureStore({ reducer: rootReducer });
