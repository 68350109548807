// Configs
import apisConfig from "../configs/apisConfig";

export const getJobApplicationFormOptionsFetch = async () => {
  try {
    const queryRoute = "/formOption/getJobApplicationFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
