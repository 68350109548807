export const createInitialSkillItem = () => {
  return {
    id: 1,
    skillName: "",
    skillDescription: "",
  };
};

export const createSkillItem = (currentSkills) => {
  const itemId = currentSkills[0]
    ? currentSkills[currentSkills.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    skillName: "",
    skillDescription: "",
  };
};
