export const createInitialEducationItem = () => {
  return {
    id: 1,
    instituteName: "",
    degreeId: null,
    degreeName: "",
    startMonth: null,
    endMonth: null,
  };
};

export const createEducationItem = (currentEducations) => {
  const itemId = currentEducations[0]
    ? currentEducations[currentEducations.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    instituteName: "",
    degreeId: null,
    degreeName: "",
    startMonth: null,
    endMonth: null,
  };
};
