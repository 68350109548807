// Configs
import apisConfig from "../configs/apisConfig";

export const createCandidateFetch = async (
  hkid,
  genderTypeId,
  fullNameCh,
  fullNameEn,
  alias,
  dateBirth,
  educationLevelId,
  photo,
  email,
  phoneNum,
  districtId,
  addressCh,
  addressEn,
  dateAvailable,
  expectedSalary,
  titleApplied,
  skills,
  workExperiences,
  educations
) => {
  try {
    const queryRoute = "/candidate/createCandidate";

    const reqBody = new FormData();

    reqBody.append("hkid", hkid);
    reqBody.append("genderTypeId", genderTypeId);
    reqBody.append("fullNameCh", fullNameCh);
    reqBody.append("fullNameEn", fullNameEn);
    reqBody.append("alias", alias);
    reqBody.append("dateBirth", dateBirth);
    reqBody.append("educationLevelId", educationLevelId);
    reqBody.append("email", email);
    reqBody.append("phoneNum", phoneNum);
    reqBody.append("districtId", districtId);
    reqBody.append("addressCh", addressCh);
    reqBody.append("addressEn", addressEn);
    reqBody.append("dateAvailable", dateAvailable);
    reqBody.append("expectedSalary", expectedSalary);
    reqBody.append("titleApplied", titleApplied);
    reqBody.append("skills", JSON.stringify(skills));
    reqBody.append("workExperiences", JSON.stringify(workExperiences));
    reqBody.append("educations", JSON.stringify(educations));

    if (photo) {
      reqBody.append("files", photo);
    }

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      body: reqBody,
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
