export const languageZhHk = {
  // Data
  area_name_ch: "area_name_ch",
  degree_name_ch: "degree_name_ch",
  district_name_ch: "district_name_ch",
  gender_type_name_ch: "gender_type_name_ch",
  education_level_name_ch: "education_level_name_ch",
  staff_contract_type_name_ch: "staff_contract_type_name_ch",
  // Text
  "請先填寫 申請職位": "請先填寫 申請職位",
  "請先填寫 期望工資": "請先填寫 期望工資",
  "期望工資 必須為數字": "期望工資 必須為數字",
  "請先填寫 最快可入職日期": "請先填寫 最快可入職日期",
  "請先填寫 電話號碼": "請先填寫 電話號碼",
  "請先填寫 區域": "請先填寫 區域",
  "請先填寫 地區": "請先填寫 地區",
  "請先填寫 英文地址": "請先填寫 英文地址",
  "請先填寫 別名": "請先填寫 別名",
  "請先填寫 出生日期": "請先填寫 出生日期",
  "請先填寫 教育程度": "請先填寫 教育程度",
  "請先填寫 性別": "請先填寫 性別",
  "請先填寫 身分證編號": "請先填寫 身分證編號",
  "請先填寫 英文全名": "請先填寫 英文全名",
  "請先填寫 機構名稱": "請先填寫 機構名稱",
  "請先填寫 學位": "請先填寫 學位",
  "請先填寫 學位名稱": "請先填寫 學位名稱",
  "請先填寫 開始月份": "請先填寫 開始月份",
  "請先填寫 技能名稱": "請先填寫 技能名稱",
  "請先填寫 合約類型": "請先填寫 合約類型",
  "請先填寫 公司名稱": "請先填寫 公司名稱",
  "請先填寫 職位": "請先填寫 職位",
  "請先填寫 工資": "請先填寫 工資",
  "工資 必須為數字": "工資 必須為數字",
  "未能提交，請再試一次": "未能提交，請再試一次",
  "已成功提交，請等待公司審批。": "已成功提交，請等待公司審批。",
  "確認要提交 入職申請 嗎？": "確認要提交 入職申請 嗎？",
  未能提交: "未能提交",
  聯絡方式: "聯絡方式",
  電話號碼: "電話號碼",
  電郵地址: "電郵地址",
  基本資料: "基本資料",
  中文名稱: "中文名稱",
  英文全名: "英文全名",
  別名: "別名",
  性別: "性別",
  身分證編號: "身分證編號",
  "出生日期 (日/月/年)": "出生日期 (日/月/年)",
  教育程度: "教育程度",
  "重新 ": "重新",
  上載個人照片: "上載個人照片",
  下一步: "下一步",
  上一步: "上一步",
  住宅地址: "住宅地址",
  區域: "區域",
  地區: "地區",
  中文地址: "中文地址",
  英文地址: "英文地址",
  申請詳情: "申請詳情",
  申請職位: "申請職位",
  期望工資: "期望工資",
  "最快可入職日期 (日/月/年)": "最快可入職日期 (日/月/年)",
  提交: "提交",
  取消: "取消",
  確認: "確認",
  "聯絡方式 / 住宅地址": "聯絡方式 / 住宅地址",
  技能s: "技能",
  工作經驗s: "工作經驗",
  教育s: "教育",
  新增技能: "新增技能",
  技能: "技能",
  "如不需填寫技能，請按此刪除": "如不需填寫技能，請按此刪除",
  刪除技能: "刪除技能",
  技能名稱: "技能名稱",
  "描述 (可不填)": "描述 (可不填)",
  新增教育: "新增教育",
  教育: "教育",
  "如不需填寫教育，請按此刪除": "如不需填寫教育，請按此刪除",
  刪除教育: "刪除教育",
  機構名稱: "機構名稱",
  學位: "學位",
  學位名稱: "學位名稱",
  "開始月份 (月/年)": "開始月份 (月/年)",
  "結束月份 (月/年) (如仍在學，可不填)": "結束月份 (月/年) (如仍在學，可不填)",
  新增工作經驗: "新增工作經驗",
  工作經驗: "工作經驗",
  "如不需填寫工作經驗，請按此刪除": "如不需填寫工作經驗，請按此刪除",
  刪除工作經驗: "刪除工作經驗",
  公司名稱: "公司名稱",
  職位: "職位",
  "結束月份 (月/年) (如仍在職，可不填)": "結束月份 (月/年) (如仍在職，可不填)",
  "工作內容 (可不填)": "工作內容 (可不填)",
  與應徵工作有關: "與應徵工作有關",
  合約類型: "合約類型",
  工資: "工資",
  有佣金: "有佣金",
};
